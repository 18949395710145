import { onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import { auth } from "../../utils/firebase";

export const AuthDetails = () => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if(user) {
                setAuthUser(user)
            } else {
                setAuthUser(null)
            }
        })

    }, [])
    return (
        <div>{authUser ? <><p>Signed In as {authUser.displayName}</p> <button className="border w-full bg-blue-400" onClick={() => auth.signOut()}>Sign Out</button></> : <p>Signed Out</p>}</div>
    )
}