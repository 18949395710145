// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAtu7lWkRZl4klUNn-zXLZMuPXNuk6lIGA",
  authDomain: "bleach-admin-app.firebaseapp.com",
  projectId: "bleach-admin-app",
  storageBucket: "bleach-admin-app.appspot.com",
  messagingSenderId: "659164873644",
  appId: "1:659164873644:web:401b2e9b00121123e03009"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)