import './App.css';
import SignIn from './components/auth/SignIn';
import {auth} from './utils/firebase'
import { useAuthState } from 'react-firebase-hooks/auth';
import Dashboard from './components/dashboard/Dashboard.js';

function App() {

const [user, loading] = useAuthState(auth);

if (loading) return <h1>Loading...</h1>;
if(!user)
return (
<div className='App'>
  <SignIn />
</div>
)

else return (
  <Dashboard />
)

}

export default App;
