// This IDs are different between QA and Production
const roleMap = {
  "860133df-211b-49db-96b3-a37cb9dba444": 'Admin',
  "86b959af-2537-4a15-a7fc-9f1cece6cdd3": 'User',
  "a9e5df48-24e9-407d-96bb-12bc7ba74f13": 'Employee User'
};

const UserTable = ({ users, handleNewUserChange, newUser, setUsers, setNewUser, submitNewUser, sendConfirmation, sendPasswordReset, updateUserField, handleDeleteUser, handleSaveUser }) => {

  const toggleEdit = (id) => {
    
    setUsers(users.map(user => {
      if (user.id === id) {
        console.log(user.role_id)
        return { ...user, isEditing: !user.isEditing };
      }
      return user;
    }));
  };
  

    return (
    <>
      <tr key="new-user">
      <td className="py-2 px-2 border-b italic">Add New User</td>
          <td className="py-2 px-2 border-b">
            <input
              type="text"
              placeholder="first name"
              value={newUser.first_name}
              onChange={(e) => handleNewUserChange('first_name', e.target.value)}
            />
          </td>
          <td className="py-2 px-2 border-b">
            <input
              type="text"
              placeholder="last name"
              value={newUser.last_name}
              onChange={(e) => handleNewUserChange('last_name', e.target.value)}
            />
          </td>
          <td className="py-2 px-2 border-b">
            <input
              type="text"
              placeholder="email"
              value={newUser.email}
              onChange={(e) => handleNewUserChange('email', e.target.value)}
            />
          </td>
          <td className="py-2 px-2 border-b">
            <input
              type="text"
              placeholder="company ID"
              value={newUser.company_id}
              onChange={(e) => handleNewUserChange('company_id', e.target.value)}
            />
          </td>
          <td className="py-2 px-2 border-b">
            
          </td>
          <td className="py-2 px-2 border-b"></td>
          <td className="py-2 px-2 border-b">
          <select
            value={newUser.role_id}
            onChange={(e) => handleNewUserChange('role_id', e.target.value)}
            >
            <option className="italic" value="">Select Role</option>
            <option value="86b959af-2537-4a15-a7fc-9f1cece6cdd3">User</option>
            <option value="860133df-211b-49db-96b3-a37cb9dba444">Admin</option>
            <option value="a9e5df48-24e9-407d-96bb-12bc7ba74f13">Employee User</option>
          </select>
          </td>
          <td className="py-2 px-2 border-b">
            <button
              className="bg-blue-950 hover-bg-blue-700 text-white font-bold py-2 px-2 w-full"
              onClick={() => submitNewUser(newUser, setUsers, setNewUser)}
            >
              Add User
            </button>
          </td>
      </tr>
      {users.map((user) => (
        
        

        <tr key={user.id}>
        <td className="py-2 px-2 border-b">{user.id}</td>
<td className="py-2 px-2 border-b">
{user.isEditing ? (
<input
  type="text"
  value={user.first_name}
  onChange={(e) => updateUserField(user.id, 'first_name', e.target.value)}
/>
) : (
user.first_name
)}
</td>
<td className="py-2 px-2 border-b">
{user.isEditing ? (
<input
  type="text"
  value={user.last_name}
  onChange={(e) => updateUserField(user.id, 'last_name', e.target.value)}
/>
) : (
user.last_name
)}
</td>
<td className="py-2 px-2 border-b">
{user.isEditing ? (
<input
  type="text"
  value={user.email}
  onChange={(e) => updateUserField(user.id, 'email', e.target.value)}
/>
) : (
user.email
)}
</td>
<td className="py-2 px-2 border-b">
{user.isEditing ? (
<input
  type="text"
  value={user.company_id}
  onChange={(e) => updateUserField(user.id, 'company_id', e.target.value)}
/>
) : (
user.company_id
)}
</td>

<td className="py-2 px-2 border-b">
{user.company ? user.company.name : 'No Company'}
</td>
<td className="py-2 px-2 border-b">{user.created_at}</td>

<td className="py-2 px-2 border-b">
{user.isEditing ? (
<select
value={user.role_id}
onChange={(e) => updateUserField(user.id, 'role_id', e.target.value)}
>
<option value="860133df-211b-49db-96b3-a37cb9dba444">Admin</option>
<option value="86b959af-2537-4a15-a7fc-9f1cece6cdd3">User</option>
<option value="a9e5df48-24e9-407d-96bb-12bc7ba74f13">Employee User</option>

</select>
) : (
  roleMap[user.role_id] || 'Unknown Role')}
</td>
          
          <td className="py-2 px-2 border-b">
            {user.isEditing ? (
              <>
                <button
                  className="bg-blue-500 hover-bg-blue-700 text-white font-bold py-2 px-2 mr-2 w-full"
                  onClick={() => handleSaveUser(user.id)}
                >
                  Save
                </button>
                <button
                  className="bg-sky-800 hover-bg-sky-700 text-white font-bold py-2 px-2 w-full"
                  onClick={() => toggleEdit(user.id, users)}
                >
                  Close Edit
                </button>
              </>
            ) : (
              <>
                <button
                  className="bg-blue-950 hover-bg-blue-700 text-white font-bold py-2 px-2 mr-2 w-full"
                  onClick={() => handleDeleteUser(user.id, users, setUsers)}
                >
                  Delete
                </button>
                <button
                  className="bg-sky-800 hover-bg-sky-700 text-white font-bold py-2 px-2 w-full"
                  onClick={() => toggleEdit(user.id, users)}
                >
                  Edit
                </button>
                <button
                  className="bg-blue-950 hover-bg-blue-700 text-white font-bold py-2 px-2 mr-2 w-full"
                  onClick={() => sendConfirmation(user.first_name, user.id, user.email)}
                >
                  Send Email Confirmation Code
                </button>
                <button
                  className="bg-sky-800 hover-bg-blue-700 text-white font-bold py-2 px-2 mr-2 w-full"
                  onClick={() => sendPasswordReset(user.first_name, user.id, user.email)}
                >
                  Send Password Reset
                </button>
              </>
            )}
          </td>
        </tr>
      ))}
    </>
  );
};

export default UserTable;
