import { toast } from "react-toastify";
import { getIdToken } from "../components/Nav";

const API_HOST = 'https://admin.bleachcyber.com';
//const API_HOST = 'http://localhost:3001';

export const fetchData = async (tab) => {
  const idToken = await getIdToken()
  const endpoint = tab === 'users' ? `${API_HOST}/api/users` : `${API_HOST}/api/companies`;

  try {
      const response = await fetch(endpoint, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      const data = await response.json();
      return data.data.map(item => ({ ...item, isEditing: false }));
  } catch (error) {
      console.error(error);
      return []; 
  }
};

  export const approveServiceProvider = async (id) => {
    const idToken = await getIdToken()
    console.log('approve function hit')
    const endpoint = `${API_HOST}/api/companies/approve/${id}`;
  
    try {
      const response = await fetch(endpoint, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
  
      if (response.status === 200) {
        toast.success('Service Provider Approved');
  
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        toast.error('Failed to approve Service Provider');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      toast.error('An error occurred while approving Service Provider');
    }
  };
  
  export const denyServiceProvider = async (id) => {
    const idToken = await getIdToken()
    const endpoint = `${API_HOST}/api/companies/deny/${id}`;
  
    try {
      const response = await fetch(endpoint, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
  
      if (response.status === 200) {
        toast.success('Service Provider Denied');
  
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        toast.error('Failed to deny Service Provider');
      }
    } catch (error) {
      toast.error('An error occurred while denying Service Provider');
    }
  };

  export const updateUser = async (id, updatedData) => {
    const idToken = await getIdToken()

    try {
      const response = await fetch(`${API_HOST}/api/user/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(updatedData),
      });
  
      if (response.ok) {
        return true; 
      } else {
        console.log('Failed to update user');
        return false; 
      }
    } catch (error) {
      console.log(error);
      return false; 
    }
  };
  export const updateCompany = async (id, updatedData) => {
    const idToken = await getIdToken()

    try {
      const response = await fetch(`${API_HOST}/api/company/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        
        body: JSON.stringify(updatedData),
      });
  
      if (response.ok) {
        return true;
      } else {
        console.log('Failed to update company');
        return false;
      }
    } catch (error) {
      console.log(error);
      return false; 
    }
  };

  export const deleteUser = async (id, users, setUsers) => {
    const idToken = await getIdToken()

    try {
      const response = await fetch(`${API_HOST}/api/user/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
  
      if (response.ok) {
        setUsers(users.filter(user => user.id !== id));
        await toast.success('User deleted');
      } else {
        console.log('Failed to delete user');
      }
    } catch (error) {
      console.log(error);
    }
  };
  export const deleteCompany = async (id, users, setUsers) => {
    const idToken = await getIdToken()

    try {
      const response = await fetch(`${API_HOST}/api/company/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
  
      if (response.ok) {
        setUsers(users.filter(company => company.id !== id));
        await toast.success('Company deleted');
      } else {
        console.log('Failed to delete company');
      }
    } catch (error) {
      console.log(error);
    }
  };


  export const submitNewUser = async (newUser, setUsers, setNewUser) => {
    const idToken = await getIdToken()

    try {
      const response = await fetch(`${API_HOST}/api/users`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          first_name: newUser.first_name,
          last_name: newUser.last_name,
          email: newUser.email,
          company_id: newUser.company_id,
          is_active: newUser.is_active,
          is_admin: newUser.is_admin,
          role_id: newUser.role_id
        }),
      });
  
      if (response.ok) {
        toast.success('New user added');
  
        const fetchDataResponse = await fetch(`${API_HOST}/api/users`, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });
        const data = await fetchDataResponse.json();
        setUsers(data.data);
  
        setNewUser({
          first_name: '',
          last_name: '',
          email: '',
          company_id: '',
          is_active: '',
          is_admin: '',
          role_id: ''
        });
        
      } else {
        toast.error('Failed to add user');
      }
    } catch (error) {
      console.error(error);
    }
  };

  //TODO - working here
  export const submitNewCompany = async (newCompany, setCompanies, setNewCompany) => {
    const idToken = await getIdToken()

    try {
      const response = await fetch(`${API_HOST}/api/companies`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(newCompany),
      });
  
      if (response.ok) {
        
        toast.success('New company added');
        const fetchDataResponse = await fetch(`${API_HOST}/api/companies`, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });
        const data = await fetchDataResponse.json();
        setCompanies(data.data);
        
        setNewCompany({
          name: '',
          company_type: 'CUSTOMER',
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        console.error('Failed to add company');
      }
    } catch (error) {
      console.error(error);
    }
  };

  export const sendConfirmation = async (first_name, userId, email) => {
    const idToken = await getIdToken()

    fetch(`${API_HOST}/api/email/confirmation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({
        username: first_name,
        email: email,
        userId: userId
      }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Confirmation email sent');
        } else {
          toast.error('Error sending confirmation');
        }
      })
      .catch((error) => console.error(error));
  };
  
  export const sendPasswordReset = async (first_name, userId, email) => {
    const idToken = await getIdToken()

    fetch(`${API_HOST}/api/email/passwordreset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({
        username: first_name,
        email: email,
        userId: userId
      }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Password Reset email sent');
        } else {
          toast.error('Error sending password reset email');
        }
      })
      .catch((error) => console.error(error));
  };