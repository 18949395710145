import React, {useState} from 'react'
import ConfirmationModal from './ConfirmationModal';

const CompanyTable = ({companies, handleDeleteCompany, newCompany, toggleCompanyEdit, setNewCompany, setCompanies, handleNewCompanyChange, submitNewCompany, approveServiceProvider, denyServiceProvider, updateCompanyField, handleSaveCompany }) => {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);

  // Function to open the modal and set the company to be deleted
  const handleDeleteClick = (companyId) => {
    setCompanyToDelete(companyId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (companyToDelete) {
      await handleDeleteCompany(companyToDelete); // Call the function passed from Dashboard
    }
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCompanyToDelete(null);
  };


  return (
    
    <>
   
     {/* 'Add New Company' row */}
     <tr key="new-company">
        <td className="py-2 px-2 border-b italic">Add New Company</td>
        
        <td className="py-2 px-2 border-b">
          <input
            type="text"
            placeholder="Company Name"
            onChange={(e) => handleNewCompanyChange('name', e.target.value)}
          />
        </td>
        <td className="py-2 px-2 border-b">
        
        </td>
        <td className="py-2 px-2 border-b">
        <select
        value={newCompany.company_type}
        onChange={(e) => handleNewCompanyChange('company_type', e.target.value)}>
        <option value="CUSTOMER">CUSTOMER</option>
        <option value="SERVICE_PROVIDER_PENDING">SERVICE_PROVIDER_PENDING</option>
        <option value="SERVICE_PROVIDER">SERVICE_PROVIDER</option>
        </select>
        </td>
        
        <td className="py-2 px-2 border-b">
          <button
            className="bg-blue-950 hover-bg-blue-700 text-white font-bold py-2 px-2 w-full"
            onClick={() => submitNewCompany(newCompany, setCompanies)}
          >
            Add Company
          </button>
        </td>
      </tr>
      {companies.map((company) => (
        <tr key={company.id}>
          <td className="py-2 px-2 border-b">{company.id}</td>
          {company.isEditing ? (
            <>
            <td className="py-2 px-2 border-b">
            <input
          type="text"
          value={company.name}
          onChange={(e) => updateCompanyField(company.id, 'name', e.target.value)}
          />
          </td>
          </>
          ) : (
            <>
            <td className="py-2 px-2 border-b">{company.name}</td>
            </>
          )}
          
          <td className="py-2 px-2 border-b">{company.created_at}</td>
          {company.isEditing ? (
            <>
            <td className="py-2 px-2 border-b">
            <select
            value={company.company_type}
            onChange={(e) => updateCompanyField(company.id, 'company_type', e.target.value)}
            >
          <option value="CUSTOMER">CUSTOMER</option>
          <option value="SERVICE_PROVIDER">SERVICE_PROVIDER</option>
          <option value="SERVICE_PROVIDER_PENDING">SERVICE_PROVIDER_PENDING</option>
          </select>
          </td>
            </>
          ) : (
            <>
            <td className="py-2 px-2 border-b">{company.company_type}</td>
            </>
          )}
          
          <td className="py-2 px-2 border-b">
          {company.isEditing ? (
              <>
                <button
                  className="bg-blue-500 hover-bg-blue-700 text-white font-bold py-2 px-2 mr-2 w-full"
                  onClick={() => handleSaveCompany(company.id)}
                >
                  Save
                </button>
                <button
                  className="bg-sky-800 hover-bg-sky-700 text-white font-bold py-2 px-2 w-full"
                  onClick={() => toggleCompanyEdit(company.id)}
                >
                  Close Edit
                </button>
              </>
            ) : (
              <>
                <button
                  className="bg-blue-950 hover-bg-blue-700 text-white font-bold py-2 px-2 mr-2 w-full"
                  onClick={() => handleDeleteClick(company.id, companies, setCompanies)}
                >
                  Delete
                </button>
                <button
                  className="bg-sky-800 hover-bg-sky-700 text-white font-bold py-2 px-2 w-full"
                  onClick={() => toggleCompanyEdit(company.id)}
                >
                  Edit
                </button>
                
              </>
            )}
            {company.company_type === "SERVICE_PROVIDER_PENDING" ? (
              <>
              <div className="flex justify-center">
                <button
                  className="bg-blue-500 hover-bg-blue-700 text-white font-bold py-2 px-2 w-2/4"
                  onClick={() => approveServiceProvider(company.id)}
                >
                  Approve MSP
                </button>
                <button
                  className="bg-red-500 hover-bg-red-700 text-white font-bold py-2 px-2 w-2/4"
                  onClick={() => denyServiceProvider(company.id)}
                >
                  Deny MSP
                </button>
                </div>
              </>
            ) : null}
          </td>
        </tr>
      ))}

       <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this company? It cannot be recovered"
      />
    </>
  );
};

export default CompanyTable;
