export default function ConfirmationModal({ isOpen, onClose, onConfirm, message }) {
    if (!isOpen) return null;
  
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <p className="text-lg mb-4">{message}</p>
          <div className="flex justify-end space-x-4">
            <button onClick={onClose} className="px-4 py-2 rounded bg-red-500 text-white hover:bg-red-600">Cancel</button>
            <button onClick={onConfirm} className="px-4 py-2 rounded bg-green-500 text-white hover:bg-green-600">Confirm</button>
          </div>
        </div>
      </div>
    );
  }