import React from 'react';

const TabButton = ({ selectedTab, handleTabChange, tabName, children }) => {
  return (
    <button
      onClick={() => handleTabChange(tabName)}
      className={`tab-button ${selectedTab === tabName ? 'active' : ''} font-bold px-2 py-1 mr-2 border-b-2 border-transparent hover:border-blue-500 focus:outline-none focus:border-blue-500`}
    >
      {children}
    </button>
  );
};

export default TabButton;
