import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../utils/firebase";
import { AuthDetails } from "./auth/AuthDetails";
import { FcGoogle } from "react-icons/fc";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword
} from "firebase/auth";
import { useState } from "react";

export const getIdToken = async () => {
  const user = auth.currentUser;
  if (user) {
    return await user.getIdToken();
  } else {
    throw new Error("No user logged in");
  }
};

export default function Nav() {
  const [user, loading] = useAuthState(auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const googleProvider = new GoogleAuthProvider();

  const GoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // Handle the result
    } catch (error) {
      console.log(error);
    }
  };

  const emailPasswordLogin = async () => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      // Handle the result
    } catch (error) {
      console.log(error.message);
    }
  };

  

  return (
    <nav className="flex justify-between items-center py-5 border-b-2">
      <a className="" href="/"> 
      <img src="/bleach-08.png" height={100} width={100} alt="bleach-logo"></img>
      
      </a>
      <ul className="flex items-center gap-5">
        <a className="text-lg font-medium" href="/">
          User Management Dashboard
        </a>
        {!user && (
          <>
          <button
          onClick={GoogleLogin}
          className="text-white bg-gray-700 p-4 w-full font-medium rounded-lg flex align-middle gap-2">
          <FcGoogle className="text-2xl" />
          Sign in with Google
        </button>
          Or
          <div className="flex gap-2">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="px-4 py-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:outline-none"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="px-4 py-2 border border-gray-300 rounded-lg focus:border-blue-500 focus:outline-none"
          />
          <button 
            onClick={emailPasswordLogin}
            className="bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-opacity-50"
          >
            Sign in
          </button>
        </div>
       </>
        
        )}
        {user && (
          <div>
            <a href="/dashboard">
              <img
                referrerPolicy="no-referrer"
                className="w-12 rounded-full"
                src={user.photoURL}
                alt=""
              />
            </a>
          </div>
        )}
      </ul>
      <AuthDetails />
    </nav>
  );
}