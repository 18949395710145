import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserTable from './UserTable';
import CompanyTable from './CompanyTable';
import TabButton from './TabButton';
import { fetchData, updateUser, submitNewUser, submitNewCompany, deleteUser, sendConfirmation, sendPasswordReset, approveServiceProvider, denyServiceProvider, deleteCompany, updateCompany } from '../../utils/api';

function Dashboard() {
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [newCompany, setNewCompany] = useState({
    name: '',
    company_type: 'CUSTOMER'
  });
  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company_id: '',
    company_name: '',
    is_active: '',
    is_admin: '',
    role_id: '',
    photo: '',
    phone: ''
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTab, setSelectedTab] = useState('users'); 



  useEffect(() => {
    const fetchDataAsync = async () => {
      const data = await fetchData(selectedTab);
      if (selectedTab === 'users') {
        setUsers(data);
      } else {
        setCompanies(data);
      }
    };
  
    fetchDataAsync();
  }, [selectedTab]);
  
  

  const handleTabChange = (tab) => {
    setSelectedTab(tab); 
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleNewUserChange = (field, value) => {
    setNewUser({ ...newUser, [field]: value });
  };

  const handleNewCompanyChange = (field, value) => {
    setNewCompany({ ...newCompany, [field]: value });
  };

  const saveUser = async (id, users, setUsers) => {
    const updatedUser = users.find(user => user.id === id);
    const updatedData = {
        first_name: updatedUser.first_name,
        last_name: updatedUser.last_name,
        email: updatedUser.email,
        company_id: updatedUser.company_id,
        is_admin: updatedUser.is_admin,
        role_id: updatedUser.role_id 
    };
//    toggleEdit(id);
    const success = await updateUser(id, updatedData);
    if (success) {
      setUsers(users.map(user => user.id === id ? { ...user, ...updatedData } : user));
      toast.success('User updated');
      
    } else {
      toast.error('Failed to update user');
    }
  };

  const saveCompany = async (id, companies, setCompanies) => {
    const updatedCompany = companies.find(company => company.id === id);
    console.log(updatedCompany)
    const updatedData = {
      company_name: updatedCompany.company_name,
      company_type: updatedCompany.company_type
    }
    toggleCompanyEdit(id)
    const success = await updateCompany(id, updatedData);
    if (success) {
      setCompanies(companies.map(company => company.id === id ? {...company, ...updatedData} : company));
      toast.success('Company updated');
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      toast.error('Failed to update company');
    }
  }
  

  const updateUserField = (userId, field, value) => {
    
    setUsers(users.map(user => {
        if (user.id === userId) {
            return { ...user, [field]: value };
        }
        return user;
    }));
};

  const updateCompanyField = (companyId, field, value) => {
    setCompanies(companies.map(company => {
      if (company.id === companyId) {
        return { ...company, [field]: value };
      }
      return company;
    }));
  };

  const handleDeleteUser = async (id) => {
    await deleteUser(id, users, setUsers);
    };

  const handleSaveUser = async (id) => {
    await saveUser(id, users, setUsers)
    }
  
    const handleSaveCompany = async (id) => {
    await saveCompany(id, companies, setCompanies)
    }

    const handleDeleteCompany = async (id) => {
      await deleteCompany(id, companies, setCompanies);
      };

    
  
    const toggleCompanyEdit = (id) => {
      setCompanies(companies.map(company => {
        if (company.id === id) {
          return { ...company, isEditing: !company.isEditing };
        }
        return company;
      }));
    };
  

    const getFilteredResults = () => {
      const lowerSearchQuery = searchQuery.toLowerCase();
    
      if (selectedTab === 'users') {
        return users.filter(user =>
          (user.first_name?.toLowerCase() || '').includes(lowerSearchQuery) ||
          (user.last_name?.toLowerCase() || '').includes(lowerSearchQuery) ||
          (user.email?.toLowerCase() || '').includes(lowerSearchQuery) ||
          (user.company?.name?.toLowerCase() || '').includes(lowerSearchQuery)
        );
      } else if (selectedTab === 'companies') {
        return companies.filter(company =>
          (company.name?.toLowerCase() || '').includes(lowerSearchQuery) ||
          (company.created_at?.toLowerCase() || '').includes(lowerSearchQuery) ||
          (company.company_type?.toLowerCase() || '').includes(lowerSearchQuery)
        );
      }
      
      return []; // Return an empty array if neither users nor companies are selected
    };
    
  
  const renderTableHeaders = () => {
    if (selectedTab === 'users' && users) {
      return (
        <tr>
          <th className="py-2 px-2 border-b">#</th>
          <th className="py-2 px-2 border-b">First Name</th>
          <th className="py-2 px-2 border-b">Last Name</th>
          <th className="py-2 px-2 border-b">Email</th>
          <th className="py-2 px-2 border-b">Company ID</th>
          <th className="py-2 px-2 border-b">Company Name</th>
          <th className="py-2 px-2 border-b">Created At</th>
          <th className="py-2 px-2 border-b">User Role</th>
          <th className="py-2 px-2 border-b">Actions</th>
        </tr>
      );
    } else if (selectedTab === 'companies' && companies) {
      return (
        <tr>
          <th className="py-2 px-2 border-b">ID</th>
          <th className="py-2 px-2 border-b">Name</th>
          <th className="py-2 px-2 border-b">Created At</th>
          <th className="py-2 px-2 border-b">Company Type</th>
          <th className="py-2 px-2 border-b">Actions</th>
        </tr>
      );
    }
  };


  const renderTableData = () => {
    const filteredResults = getFilteredResults();
    if (selectedTab === 'users') {
      return (
        <UserTable
          users={filteredResults}
          handleNewUserChange={handleNewUserChange}
          submitNewUser={submitNewUser}
          deleteUser={deleteUser}
          sendConfirmation={sendConfirmation}
          sendPasswordReset={sendPasswordReset}
          saveUser={saveUser}
          newUser={newUser}
          setNewUser={setNewUser}
          setUsers={setUsers}
          updateUserField={updateUserField}
          handleDeleteUser={handleDeleteUser}
          handleSaveUser={handleSaveUser}
        />
      );
    } else if (selectedTab === 'companies') {
      return (
        <CompanyTable
          companies={filteredResults}
          handleNewCompanyChange={handleNewCompanyChange}
          submitNewCompany={submitNewCompany}
          toggleCompanyEdit={toggleCompanyEdit}
          setCompanies={setCompanies}
          saveCompany={saveCompany}
          handleDeleteCompany={handleDeleteCompany}
          newCompany={newCompany}
          updateCompanyField={updateCompanyField}
          approveServiceProvider={approveServiceProvider}
          denyServiceProvider={denyServiceProvider}
          handleSaveCompany={handleSaveCompany}
        />
      );
    }
  };

  return (
    <div className='flex justify-center'>
    <div className="w-full max-w-screen-l mt-20">
      
      <ToastContainer />
      <div className="tabs border-b border-gray-300 pb-2">
        <TabButton
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          tabName="users"
        >
          Users
        </TabButton>
        <TabButton
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          tabName="companies"
        >
          Companies
        </TabButton>
      </div>
      <input
        type="text"
        placeholder={`Search ${selectedTab === 'users' ? 'users' : 'companies'}...`}
        value={searchQuery}
        onChange={handleSearchInputChange}
        className="mb-4 p-2 border border-gray-300"
      />
      <table className="w-full bg-white border border-gray-300">
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableData()}</tbody>
      </table>
      </div>
    </div>
  );
}

export default Dashboard;
